import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import BasePortableText from '@sanity/block-content-to-react'
import ReactMarkdown from 'react-markdown'
import { rhythm } from '../utils/typography'
import './blog.css'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.post
    const siteTitle = this.props.data.post.metaTitle
    const { previous, next } = this.props.pageContext
    console.log(this.props.pageContext)
    const serializers = {
      types: {
        authorReference: ({ node }) => (
          <span>
            {post.author.firstname} {post.author.lastname}
          </span>
        ),
      },
    }
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.title} description={post.shortDescription} />

        <div className="blog-page page-heading text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <h2 class="text-center text-white m-0">{post.title}</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="container pt-5 pb-4">
          <div className="blog-single-page subheading">
            {post.mainImage && (
              <img
                src={post.mainImage.asset.url}
                alt={post.mainImage.originalFilename}
              />
            )}
            {post._rawBody && (
              <BasePortableText
                blocks={post._rawBody}
                serializers={serializers}
              />
            )}

            {post.htmlBody && (
              <ReactMarkdown source={post.htmlBody} escapeHtml={false} />
            )}

            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />

            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={`/blog/${previous.slug.current}`} rel="prev">
                    ← {previous.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={`/blog/${next.slug.current}`} rel="next">
                    {next.title} →
                  </Link>
                )}
              </li>
            </ul>
            <div className="d-flex justify-content-center mt-4">
              <Link className="btn-global nav-cta" to="/blog">
                Return To Blog List
              </Link>
            </div>

            <br />
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostTemplateQuery($slug: String!) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      id
      title
      shortDescription
      htmlBody
      author {
        image {
          asset {
            _id
            url
          }
        }
      }
      mainImage {
        asset {
          id
          url
          originalFilename
          fluid {
            src
          }
        }
      }
      publishedAt
      categories {
        _id
        title
      }
      slug {
        current
      }
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`
